// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_q4 d_gv d_cs";
export var heroHeaderCenter = "r_gw d_gw d_cs d_dw";
export var heroHeaderRight = "r_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "r_q5 d_gr d_cw";
export var heroParagraphCenter = "r_gs d_gs d_cw d_dw";
export var heroParagraphRight = "r_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "r_q6 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "r_q7 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "r_q8 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "r_q9 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "r_rb d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "r_rc s_rc";
export var heroExceptionNormal = "r_rd s_rd";
export var heroExceptionLarge = "r_rf s_rf";
export var Title1Small = "r_rg s_rg s_rn s_rp";
export var Title1Normal = "r_rh s_rh s_rn s_rq";
export var Title1Large = "r_rj s_rj s_rn s_rr";
export var BodySmall = "r_rk s_rk s_rn s_rH";
export var BodyNormal = "r_rl s_rl s_rn s_rJ";
export var BodyLarge = "r_rm s_rm s_rn s_rK";