// extracted by mini-css-extract-plugin
export var noPadding = "B_fj d_fj";
export var videoFrameWrapper = "B_tQ d_kS d_b5";
export var videoIframeStyle = "B_pS d_d5 d_w d_H d_by d_b1 d_R";
export var videoLayoutRight = "B_tR d_kR d_bK";
export var videoInnerWrapper = "B_tS d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5";
export var videoText = "B_tT d_dw d_w";
export var videoInnerInnerWrapper = "B_tV d_bz d_bD d_bJ";
export var btnWrapper = "B_d2 d_w d_bz d_bD d_cj";
export var btn = "B_mB d_b1 d_bz d_bD d_bP d_cH d_b6 d_dB d_n s_r4 s_rS";
export var icon = "B_p9 d_cC";
export var textLeft = "B_dv";
export var textCenter = "B_dw";
export var textRight = "B_dx";